import React, { useState, useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Link } from 'react-router-dom';

import DateDisplay from 'elements/DateDisplay';

import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import globalConfig from 'config/globalConfig';

export default function RightBar(props) {
    return (
        <div className={'flex flex-col gap-6 w-full ' + (props.className || '')}>

            <NewsLister className='hidden lg:flex' />

            {getValueFromParameterMap(getWebsite().globalContent, 'banner') && <Banner data={getValueFromParameterMap(getWebsite().globalContent, 'banner')} />}

            <div className='grid grid-cols-2 gap-6'>

                <SubBanner className='p-6' image='/assets/images/banner/bga.jpg' url='https://bgazrt.hu/' />
                <SubBanner className='p-2' image='/assets/images/banner/minelokseg.jpg' url='https://kormany.hu/miniszterelnokseg' />

            </div>

            {getValueFromParameterMap(getWebsite().globalContent, 'facebook') && <FacebookPage url={getValueFromParameterMap(getWebsite().globalContent, 'facebook')} />}

            <MemberOrganizationLister />

        </div>
    );
}

function MemberOrganizationLister(props) {
    return (
        <div className='bg-white shadow-lg rounded-lg px-5 gap-4 py-4 flex flex-row items-center'>

            <div className='text-xs text-oszsz-gray tracking-wide'>Tagszervezeteink:</div>

            <div className='flex flex-row justify-between flex-1'>

                {getValueFromParameterMap(getWebsite().globalContent, 'sites').contentList.map((item, index) => (getValueFromParameterMap(item, 'name') !== getValueFromParameterMap(getWebsite().globalContent, 'name') && <MemberOrganization key={index} data={item} />))}

            </div>
        </div>
    );
}

function MemberOrganization(props) {
    return (
        <a href={getValueFromParameterMap(props.data, 'url')} className='hover:scale-105 transition'>
            <img className='h-7 xl:h-9' alt='logo' src={getValueFromParameterMap(props.data, 'logo').downloadUrl} />
        </a>
    );
}

function NewsLister(props) {
    const maxListCount = 3;

    return (
        <div className={'flex flex-col grow gap-3 ' + (props.className || '')}>

            {/* <div className='uppercase text-sm text-oszsz-gray-dark font-bold ml-16'>Friss híreink</div> */}

            <div className='flex flex-col gap-2'>
                {getValueFromParameterMap(getWebsite().globalContent, 'news-list').contentList.map((item, index) => (index < maxListCount && <NewsListerItem key={index} data={item} />))}
            </div>

            <Link to={globalConfig.etc.newsPath} className='button'>További híreink</Link>

        </div>
    );
}

function NewsListerItem(props) {
    let _data = {
        creationDate: props.data.publicCreationDate,
        heading: getValueFromParameterMap(props.data, 'heading'),
        subHeading: getValueFromParameterMap(props.data, 'sub-heading'),
        lead: getValueFromParameterMap(props.data, 'lead'),
        url: getValueFromParameterMap(props.data, 'url'),
        site: getValueFromParameterMap(props.data, 'site'),
    };

    const getIconBySite = (site) => {
        const sites = getValueFromParameterMap(getWebsite().globalContent, 'sites');
        for (let i = 0; i < sites.contentList.length; i++)
            if (getValueFromParameterMap(sites.contentList[i], 'nev-rovid') === site)
                return getValueFromParameterMap(sites.contentList[i], 'logo').downloadUrl;
        return null;
    }

    return (
        <div className='flex flex-row items-start'>

            <div className='w-16 aspect-square bg-white flex items-center justify-center rounded-l-lg'>
                <img className='h-[36px]' alt='logo' src={getIconBySite(_data.site)} />
            </div>

            <Link to={_data.url} className='flex-1 bg-oszsz-blue hover:bg-oszsz-blue-dark transition-all p-4 flex flex-col rounded-lg rounded-tl-none text-white'>

                <DateDisplay className='text-xxs opacity-75 mb-1' date={new Date(_data.creationDate)} />

                {_data.heading && <div className={'text-sm font-bold ' + (_data.subHeading ? 'mb-1' : 'mb-2')}>{_data.heading}</div>}
                {_data.subHeading && <div className='text-sm mb-2'>{_data.subHeading}</div>}
                {_data.lead && <div className='text-xs line-clamp-3' dangerouslySetInnerHTML={{ __html: _data.lead }} />}

            </Link>

        </div>
    );
}

function Banner(props) {
    return (
        <a href={getValueFromParameterMap(props.data, 'link-url')} className='w-full rounded-lg overflow-hidden shadow-lg shrink-0 hover:ring-4 ring-gray-100 transition'>
            <img className='w-full' alt='banner' src={getValueFromParameterMap(props.data, 'image').downloadUrl} />
        </a>
    );
}

function SubBanner(props) {
    return (
        <a href={props.url} className={'aspect-square flex items-center justify-center rounded-lg overflow-hidden shadow-lg shrink-0 hover:ring-4 ring-gray-100 bg-white transition ' + (props.className || '')}>
            <img className='w-full' alt='banner' src={props.image} />
        </a>
    );
}

function FacebookPage(props) {
    const { width, ref } = useResizeDetector();
    const [embedWidth, setEmbedWidth] = useState(266);

    let timer = null;

    useEffect(() => {
        clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timer = setTimeout(() => {
            setEmbedWidth(Math.round(width));
        }, 150);
        return () => clearTimeout(timer);
    }, [width]);

    useEffect(() => {
        let parserTimer = setTimeout(() => {
            // eslint-disable-next-line no-undef
            FB.XFBML.parse();
        }, 100);
        return () => clearTimeout(parserTimer);
    }, [embedWidth]);

    return (
        <div ref={ref} className='w-full max-w-[500px] min-w-[180px] min-h-[130px] rounded-lg overflow-hidden shadow-lg'>
            <div
                key={embedWidth}
                class="fb-page"
                className="fb-page"
                data-href={props.url}
                data-tabs=""
                data-width={embedWidth}
                data-height="130"
                data-small-header="false"
                data-adapt-container-width="true"
                data-hide-cover="false"
                data-show-facepile="true">
                {/* <blockquote cite="https://www.facebook.com/SemmelweisErdekvedelmiSzovetseg/" class="fb-xfbml-parse-ignore">
                    <a href="https://www.facebook.com/SemmelweisErdekvedelmiSzovetseg/">SESZ</a>
                </blockquote> */}
            </div>
        </div>
    );
}