import { useEffect } from 'react';

import NBossCMSClient from 'cms/NBossCMS.js';

import nBossConnection from 'services/nBossConnection.js';

import 'react-image-lightbox/style.css';

function App() {
  useEffect(() => {
    nBossConnection.setErrorHandler(error => {
      // if (typeof error.response.status !== 'undefined' && error.response.status === 500) alert('Internal Server Error!');  else 
      alert(error);
    });
  });

  return (<NBossCMSClient domainName='oszsz.dev.office.creo.hu' />);
}

export default App;
