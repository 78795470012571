import { Link } from 'react-router-dom';

import DateDisplay from 'elements/DateDisplay';
import Logo from 'elements/Logo.js';
import DynamicIcon from 'elements/DynamicIcon.js';

import globalConfig from 'config/globalConfig';
import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function Footer(props) {
    return (
        <div className={'w-full flex flex-col items-center bg-oszsz-blue-dark ' + (props.className || '')}>

            <div className='max-w-oszsz-boxed site-px py-6 sm:py-12 flex flex-col gap-10 lg:gap-12'>
                <FooterTop />
                <FooterBottom />
            </div>

        </div>
    );
}

function FooterTop(props) {
    return (
        <div className='flex items-center justify-center bg-oszsz-blue-dark text-white'>

            <div className='flex flex-col lg:flex-row gap-10 xl:gap-12'>
                <AboutUsBlock className='flex-1' />
                <NewsBlock className='flex-1' />
                <ContactBlock className='flex-1' />
            </div>

        </div>
    );
}

function TitledBlock(props) {
    return (
        <div className={'flex flex-col gap-4 ' + (props.className || '')}>

            {props.title && <div className='uppercase font-bold text-xl'>{props.title}</div>}

            {props.children}

        </div>
    );
}

function AboutUsBlock(props) {
    return (
        <TitledBlock className={'flex flex-col gap-8 text-white ' + (props.className || '')}>

            <Logo alternate />

            {getValueFromParameterMap(getWebsite().footerContent, 'introduction') && <div className='text-xs leading-6' dangerouslySetInnerHTML={{ __html: getValueFromParameterMap(getWebsite().footerContent, 'introduction') }} />}

            {/* <div className='text-xs leading-6'>{getValueFromParameterMap(getWebsite().footerContent, 'introduction')}</div> */}

        </TitledBlock>
    );
}

function NewsBlock(props) {
    const maxListCount = 2;

    return (
        <TitledBlock className={'' + (props.className || '')} title='Legfrissebb híreink'>

            {getValueFromParameterMap(getWebsite().globalContent, 'news-list').contentList.map((item, index) => (index < maxListCount && <NewsBlockItem key={index} data={item} />))}

        </TitledBlock>
    );
}

function NewsBlockItem(props) {
    let _data = {
        creationDate: props.data.publicCreationDate,
        heading: getValueFromParameterMap(props.data, 'heading'),
        subHeading: getValueFromParameterMap(props.data, 'sub-heading'),
        lead: getValueFromParameterMap(props.data, 'lead'),
        url: getValueFromParameterMap(props.data, 'url'),
    };

    return (
        <div className='flex flex-col text-white'>

            <DateDisplay className='text-xxs opacity-75 mb-2' date={new Date(_data.creationDate)} />

            {_data.heading && <Link to={_data.url} className='group'><div className='text-base font-bold uppercase mb-1 group-hover:text-oszsz-blue transition'>{_data.heading}</div></Link>}
            {_data.subHeading && <div className='text-xs mb-1'>{_data.subHeading}</div>}
            {_data.lead && <div className='text-xs leading-6 line-clamp-3' dangerouslySetInnerHTML={{ __html: _data.lead }} />}

        </div>
    );
}

function ContactBlock(props) {
    let _data = {
        email: getValueFromParameterMap(getWebsite().globalContent, 'email'),
        phone: getValueFromParameterMap(getWebsite().globalContent, 'phone'),
        mobile: getValueFromParameterMap(getWebsite().globalContent, 'mobile')
    };

    return (
        <TitledBlock className={'' + (props.className || '')} title='Kapcsolat'>

            <div className='bg-black bg-opacity-20 rounded-lg p-6 flex flex-col gap-4'>

                <div className='text-sm font-bold'>{getValueFromParameterMap(getWebsite().globalContent, 'name')}</div>

                <div className='flex flex-col gap-2'>
                    {_data.email && <ContactBlockLine title='E-mail' icon='FaEnvelope' value={_data.email} href={'mailto:' + _data.email} />}
                    {_data.phone && <ContactBlockLine title='Telefon' icon='FaPhoneAlt' value={_data.phone} href={'tel:' + _data.phone} />}
                    {_data.mobile && <ContactBlockLine title='Mobil' icon='FaMobileAlt' value={_data.mobile} href={'tel:' + _data.mobile} />}
                </div>

            </div>

            <Link to={globalConfig.etc.contactPath} className='button hover:bg-white hover:text-oszsz-blue-dark'>Küldjön üzenetet számunkra</Link>

        </TitledBlock>
    );
}

function ContactBlockLine(props) {
    return (
        <div className='flex flex-col gap-2 text-xs'>

            <div className='flex flex-row items-center gap-2 text-oszsz-blue'>
                <DynamicIcon className='' iconName={props.icon} />
                <div className='uppercase font-bold'>{props.title}</div>
            </div>

            <a href={props.href} className='hover:text-oszsz-blue transition'>{props.value}</a>

        </div>
    );
}

function FooterBottom(props) {
    return (
        <div className='flex flex-col lg:flex-row items-center lg:justify-between gap-4 lg:gap-0 px-6 py-5 bg-white rounded-lg shadow-xl'>

            <div className='flex flex-col gap-4 md:gap-1 items-center lg:items-start'>

                <div className='flex flex-col md:flex-row flex-wrap items-center justify-center divide-x-0 md:divide-x-2 gap-2 md:gap-0 divide-oszsz-blue-dark divide-opacity-10'>
                    {getValueFromParameterMap(getWebsite().globalContent, 'menu') ? getValueFromParameterMap(getWebsite().globalContent, 'menu').itemList.map((item) => <FooterBottomMenuItem key={item.id} data={item} />) : null}
                </div>

                <div className='text-xs text-oszsz-gray text-center'>2022 © {getValueFromParameterMap(getWebsite().globalContent, 'name')} - Minden jog fenntartva.</div>

            </div>

            <div className='flex flex-col lg:flex-row gap-4 items-center'>

                <a href='https://creo.hu/' className='relative w-[76px] h-[26px] group'>
                    <img className='absolute transition group-hover:opacity-0 opacity-100' alt='Creo Group' src='/assets/images/footer/creo_logo_n.png' />
                    <img className='absolute transition group-hover:opacity-100 opacity-0' alt='Creo Group' src='/assets/images/footer/creo_logo_h.png' />
                </a>

                <button className='w-8 h-8 bg-oszsz-gray-light hover:bg-oszsz-blue group transition rounded-lg flex items-center justify-center' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    <DynamicIcon className='text-sm text-oszsz-gray group-hover:text-white transition' iconName={'FaChevronUp'} />
                </button>

            </div>

        </div>
    );
}

function FooterBottomMenuItem(props) {
    return (
        <Link className='first:pl-0 last:pr-0 px-2 flex text-xs md:text-xxs lg:text-xs text-oszsz-blue-dark hover:text-oszsz-blue transition font-bold uppercase whitespace-nowrap' to={props.data.fullPath}>
            {props.data.title}
        </Link>
    );
}