import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import DateDisplay from 'elements/DateDisplay';

import CmsFrontendControl from 'services/CmsFrontendControl.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getWebsite } from 'utils/WebsiteUtils.js';

const listLength = 20;

export default function NewsLister(props) {
    const [news, setNews] = useState(null);

    useEffect(() => {
        setNews(getValueFromParameterMap(getWebsite().globalContent, 'news-list').contentList)
    }, [])

    const loadModeNews = (_length) => {
        CmsFrontendControl.getList(getValueFromParameterMap(getWebsite().globalContent, 'news-list').key, news ? (news.length) : 0, _length ? _length : listLength).then(result => {
            setNews(news ? news.concat(result.contentList) : result.contentList);
        });
    }

    return (
        <div className={'w-full flex flex-col gap-6 ' + (props.className || '')}>

            {/* {!props.mainPage && <div>
                <h1>{getValueFromParameterMap(getWebsite().globalContent, 'name')}</h1>
                <h1>Híreink</h1>
            </div>} */}

            <div className='w-full flex flex-col'>
                {news && news.map((item, index) => (<NewsItem key={index} data={item} mainPage={props.mainPage} />))}
            </div>

            <button className='button' onClick={() => loadModeNews()}>Korábbi híreink megtekintése</button>

        </div>
    );
}

function NewsItem(props) {
    let _data = {
        creationDate: props.data.publicCreationDate,
        heading: getValueFromParameterMap(props.data, 'heading'),
        subHeading: getValueFromParameterMap(props.data, 'sub-heading'),
        lead: getValueFromParameterMap(props.data, 'lead'),
        url: getValueFromParameterMap(props.data, 'url'),
    };

    return (
        <div className={'flex flex-col gap-6 content py-6 last:pb-0 ' + (props.mainPage ? 'border-b last:border-b-0 first:pt-0' : 'border-t')}>

            <div className='flex flex-col gap-3'>

                <DateDisplay className='text-xs text-oszsz-gray' date={new Date(_data.creationDate)} />

                {_data.heading && <Link to={_data.url} className='group'><h1 className='group-hover:text-oszsz-blue transition'>{_data.heading}</h1></Link>}

                {_data.subHeading && <h2>{_data.subHeading}</h2>}

            </div>

            {_data.lead && <section className='' dangerouslySetInnerHTML={{ __html: _data.lead }} />}

        </div>
    );
}